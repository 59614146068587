@import '../../../../../Mixins.scss';

.agx-propertyConfirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 1000px;

  @include desktop {
    min-width: 800px;
  }

  width: -webkit-fill-available;
  width: -moz-available;
  padding: 30px 20px 70px 20px;

  @include tabletLandscape {
    padding: 30px 20px;
  }

  @include tabletPortrait {
    padding: 30px 20px;
  }

  > .agx-buttonCollection {
    @include mobile {
      gap: 12px;
      max-width: 440px;
    }

    @include tabletPortrait {
      max-width: 440px;
    }

    @include tabletLandscape {
      max-width: 440px;
    }

    @include desktop {
      max-width: 800px;
    }
  }
}

.formContent.formContentPropertySearch:has(.agx-propertyConfirmation) {
  padding-bottom: 0;
  padding-top: 0;

  &:has(.singleProperty) {
    @include desktop {
      padding-top: calc(50vh - 200px);
    }

    @include mobile {
      padding-top: calc(50vh - 400px);
    }

    @include tabletPortrait {
      padding-top: calc(50vh - 400px);
    }
  }

  @include desktop {
    width: 920px;
  }

  @include tabletLandscape {
    padding-top: 50px;
  }
}

.propertyConfirmationAddressManualBtn {
  background: none;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.06px;
  text-decoration-line: underline;
  color: var(--brand-text-primary-color);
}

.animate__fadeInUp {
  --animate-duration: 400ms;
}

.titleSearchAgain {
  width: -webkit-fill-available;
  width: -moz-available;
}

.propertySearchAgainBtn svg {
  width: 24px !important;
  height: 24px !important;
}
