@import 'Mixins.scss';
@import 'App.scss';

.listingInfoPanel {
  position: relative;
  gap: 24px;
  overflow-y: auto;

  @include mobile {
    overflow-y: inherit;
  }

  .contractListingDetails,
  .contractRequestContracts {
    &::after {
      content: '';
      display: block;
      clear: both;
      position: sticky;
      margin-top: -12px;
      bottom: -0.39px;
      left: 0;
      height: 32px;
      width: -webkit-fill-available;
      width: -moz-available;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}

.campaignModalButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  z-index: 20;

  @include mobile {
    flex-direction: column;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 20px;
    margin: 0 -20px;
    background: #ffffff;
    border-top: 1px solid var(--neutral-grey-300);
  }
}

.campaignInfoPanel:has(.listingInfoPanel) {
  gap: 0;
}

.contractPanelTitle {
  > svg {
    fill: var(--neutral-grey-600);
  }
}

.listingSegmentedPickerContainer {
  @include desktop {
    width: 370px;
  }
  @include tabletLandscape {
    width: 370px;
  }
  @include tabletPortrait{
    width: 336px;
  }
}
